import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../assets/scss/modules/landing.module.scss';
import tesla_fantom from "../assets/media/images/Tesla-Fantom.png";
import tesla_mask from "../assets/media/images/Tesla-Mask.png";
import combined_shape from "../assets/media/images/combined-shape.png";
import back from "../assets/media/newicons/back.png";
import arrow from "../assets/media/newicons/arrow.png";
import arrowWhite from "../assets/media/newicons/arrowWhite.png";
import Cookies from 'universal-cookie';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../components/Footer/Footer';
import MusterModal from "../components/Modal/MusterModal";
import AutoModel from "../components/Cars/AutoModel";
import GrunschockAxios from '../Service/GrunschockAxios';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBIcon
} from 'mdbreact';
import axios from 'axios';
const cookies = new Cookies();

class Hyundai extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new GrunschockAxios(this);
    this.state = {automodelList: [],

									};
  };

	componentDidMount() {
		this.handlegetHyundai();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	handlegetHyundai = () => {
	 let self = this;
	 this.interAxios.get('/getautomodels/Hyundai',
		function(response) {
			self.setState({automodelList: response.data})
		});
	}

	handleShow = (model) => {
    this.setState({teslamodel: model, showModal: !this.state.showModal});
  }

  handleClose= () => {
    this.setState({showModal: !this.state.showModal});
  }

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let anchors = this.state.automodelList.filter(o => o.manufacturer === "Hyundai").map(o => o.pagename);
		anchors.push("lastpage");
		let navigationTooltips = this.state.automodelList.filter(o => o.manufacturer === "Hyundai").map(o => o.model);
		navigationTooltips.push("CONTACT");
		let termeklist = [];
		if (this.state.automodelList.length > 0){
		termeklist = this.state.automodelList.filter(o => o.manufacturer === "Hyundai").map(
			(o) => {
					return (
						<MDBContainer key={o.id} fluid className="section fluid-container">
						<AutoModel
													 msg={o}
													 handleShow= {this.handleShow}
													 handleClose= {this.handleClose}
													 handleNewTesla= {this.handleNewTesla}
													/>
						</MDBContainer>
									);
		});}

    return (

				<>
			<ReactFullpage
		    licenseKey = {'2C01A6F9-2F35483E-81C14CF4-C7FF7A52'}
		    scrollingSpeed = {500}
				anchors={anchors}
				menu='#myMenu'
				navigation={true}
				navigationTooltips={navigationTooltips}
				navigationPosition={'right'}
				showActiveTooltip= {true}
				responsiveWidth={800}
				responsiveHeight={750}

    render={({ state, fullpageApi }) => {
      return (
				<>
        <ReactFullpage.Wrapper>
					{termeklist}
					<MDBContainer fluid className="section fluid-container">
						<div className={styles.bodywrapper8}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow>
									<MDBCol middle md="6" size="12">
										<img src={tesla_fantom} className="tesla-model-3"/>
									</MDBCol>
									<MDBCol middle md="6" size="12" className="tesla-col2-fantom">
										<MDBRow  className="tesla-back-btn-row" start>
										<MDBBtn
												flat
												className="tesla-back-btn"
												type="btn"
												href= "/"
												>
												<img src={back} className="tesla-back-img"/>
										</MDBBtn>
										</MDBRow>
										<MDBRow start>
										<p className="tesla-model-text-fantom" style={{marginBottom: 0}}>Can't find the perfect option? Let us help you!</p>
										</MDBRow>
										<MDBRow>
										<form id="tesla">
							        <div className="grey-text">
							          <MDBInput label="Company name" icon="user" group type="text" validate error="wrong"
							            success="right" />
							          <MDBInput label="E-mail" icon="envelope" group type="email" validate error="wrong"
							            success="right" />
													<div className="custom-control custom-checkbox check-here-to-indica"   style={{height: "9vh"}}>
														<input type="checkbox" className="custom-control-input" id="invalidCheck" required />
														<label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck">
														Check here to indicate that you have read and agreed to the <a id="1" href="/Nutzungsbedingungen" target="_blank">Privacy Policy.</a></label>
													</div>
							        </div>
							        <div className="text-left">
							          <MDBBtn className="tesla-send">
							            <span className="">Send</span>
							          </MDBBtn>
							        </div>
							      </form>
										</MDBRow>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
							<Footer/>
						</div>

					</MDBContainer>
      </ReactFullpage.Wrapper>
			</>
      );
    }}
  />
	{this.state.showModal ?
		<MusterModal
			show={this.state.showModal}
			handleClose={this.handleClose}
			teslamodel={this.state.teslamodel}
			showEmailFullNameModal= {true}
			modalnev= {"emailfullname"}
			handleNewTesla= {this.handleNewTesla}
			{...this.props}/>:
	false}

	    </>

    );
  }
}
export default  withTranslation()(Hyundai);
