import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import combined_shape from "../../assets/media/images/combined-shape.png";
import close from "../../assets/media/newicons/close.png";
import{
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdbreact';

class ModalTesztvezetes extends Component {
  state = {	nev: this?.props.nev ? this.props.nev: "",
						telefonszam: this?.props.telefonszam ? this.props.telefonszam: "",
						};


  changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

 handleJelentkezes = (event) => {
  let self = this;
  event.preventDefault();
  axios.post(process.env.REACT_APP_BACKEND_URL+'/tesztvezetes',
      {nev: this.state.nev,
       telefonszam: this.state.telefonszam,
       tipus: this.props.teslamodel},
   function(response) {
      self.setState({nev: "", telefonszam:""});
   });
   this.props.handleCloseTesztvezetesModal();
 }

handleShowContactUs = () => {
	this.props.setTesztvezetes(this.state.nev, this.state.telefonszam, "tesztvezetes")
	this.props.handleShowContactUs();
	this.props.handleCloseTesztvezetes();
}


  render() {
    const { t } = this.props;
    const client = this.props.client;

    return (
      <>
					<MDBRow start className="align-items-center modal-teslamodel-row">
					<MDBCol md="3" size="2" className="text-left">
					</MDBCol>
					<MDBCol md="6" size="8" className="text-center">
					 <span className="tesztvezetes-tesla-model-3">{this.props.teslamodel}</span>
					</MDBCol>
					<MDBCol md="3" size="2" className="text-right">
					 <MDBBtn flat onClick={this.props.handleClose} className="togglebtn"><img src={close} className="img-toggle-close"/></MDBBtn>
					</MDBCol>
					</MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-standard-plus">Standard Plus</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-tesztvezetes">Tesztvezetés</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-kerem-adja-meg">Kérem, adja meg az adatokat</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <img src={combined_shape} className="tesztvezetes-combined-shape"/>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
           <MDBBtn flat onClick={this.handleShowContactUs} type="btn"  className="kapcsolat"><span className="tesztvezetes-lepjen-velunk text-lowercase">vagy lépjen velünk kapcsolatba!</span></MDBBtn>
          </MDBRow>
          <MDBRow start className="">
          <form className="modal-form" onSubmit={this.handleJelentkezes}>
            <MDBRow className="align-items-center modal-form-email-row">
            <MDBCol bottom className="px-0">
              <label htmlFor="defaultFormContactNevEx" className="grey-text">
                <span className="your-name-label">Név</span>
              </label>
              <input type="text" id="defaultFormContactNevEx" name="nev" className="form-control" onChange={this.changeHandler} value={this.state.nev} required />
            </MDBCol>
            </MDBRow>
            <MDBRow className="align-items-center modal-form-email-row">
              <label htmlFor="defaultFormContactTelefonszamEx" className="grey-text">
                <span className="your-name-label">Telefonszám</span>
              </label>
              <input type="text" id="defaultFormContactTelefonszamEx" name="telefonszam" className="form-control" onChange={this.changeHandler} value={this.state.telefonszam} required />
            </MDBRow>
            <MDBRow className="align-items-top modal-form-checkbox-row">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="invalidCheck" required />
                <label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck">
                Elfogadom az <a id="1" href="/Nutzungsbedingungen" target="_blank">Adatkezelési Nyilatkozat</a>-ban foglaltakat.</label>
              </div>
            </MDBRow>
            <MDBRow center className="modal-form-send-row">
              <MDBBtn className="landing-btn-send-email" type="submit">
                  Jelentkezés
              </MDBBtn>
            </MDBRow>
          </form>

          </MDBRow>
        </>
    );
  }
}

export default withTranslation()(ModalTesztvezetes);
