import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_w from '../../assets/media/newicons/logo-w.png';
import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBContainer,
} from 'mdbreact';

class Footer extends Component {

  constructor(props) {
    super(props);
  };

  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
			<div className="footer-div">
			<MDBContainer style={{maxWidth: "1430px"}}>
        <MDBRow center style={{height: "92px"}}>
          <MDBCol md="6" size="12" middle>
					<div className="d-none d-md-block" >
					<MDBRow start>
            <img src={logo_w} className="navbar-logo-footer"/>
					</MDBRow>
					</div>
					<div className="d-md-none d-block">
					<MDBRow center className="py-4">
            <img src={logo_w} className="navbar-logo-footer"/>
					</MDBRow>
					</div>
          </MDBCol>
          <MDBCol md="6" size="12" middle>
            <MDBRow>
              <MDBCol md="4" size="12" className="text-center py-2">
                <span className="footer-text">GrünSchock 2022</span>
              </MDBCol>
              <MDBCol md="4" size="12" className="text-center py-2">
                <a className="footer-text" href="/PrivacyPolicy">Privacy Policy</a>
              </MDBCol>
              <MDBCol md="4" size="12" className="text-center py-2">
                <a className="footer-text" href="/Impressum">Impressum</a>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
			</MDBContainer>
      </div>


    );
  }
}

export default withTranslation()(Footer);
