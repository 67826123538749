import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import combined_shape from "../../assets/media/images/combined-shape.png";
import close from "../../assets/media/newicons/close.png";
import done from "../../assets/media/newicons/done.png";
import inactive from "../../assets/media/newicons/inactive.png";
import active from "../../assets/media/newicons/active.png";
import{
	MDBModal,
	MDBModalBody,
	MDBContainer,
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdbreact';

class ModalCompanyPhone extends Component {
  state = {companyname: this?.props.companyname ? this.props.companyname: "",
						phonenumber: this?.props.phonenumber ? this.props.phonenumber: "",
						};


  changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

	handleShowContactUs = () => {
		this.props.setCompanynamePhonenumber(this.state.companyname, this.state.phonenumber, "companyphone")
		this.props.handleShowContactUs();
		this.props.handleCloseCompanyPhone();
	}

	handleNext = () => {
		this.props.setCompanynamePhonenumber(this.state.companyname, this.state.phonenumber)
		this.props.handleShowRentalTimeInsurance();
		this.props.handleCloseCompanyPhone();
	}

	handlePrevious = () => {
		this.props.setCompanynamePhonenumber(this.state.companyname, this.state.phonenumber)
		this.props.handleShowEmailFullName(this.props.teslamodel);
		this.props.handleCloseCompanyPhone();
	}

  render() {
    const { t } = this.props;
    const client = this.props.client;
    return (
      <>
					<MDBRow start className="align-items-center modal-teslamodel-row">
					<MDBCol md="3" size="2" className="text-left">
					</MDBCol>
					<MDBCol md="6" size="8" className="text-center">
					 <span className="tesztvezetes-tesla-model-3">{this.props.teslamodel}</span>
					</MDBCol>
					<MDBCol md="3" size="2" className="text-right">
					 <MDBBtn flat onClick={this.props.handleClose} className="togglebtn"><img src={close} className="img-toggle-close"/></MDBBtn>
					</MDBCol>
					</MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-standard-plus">Long-term rentals</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-tesztvezetes">990 €/months</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-kerem-adja-meg">Please fill out the form</span>
          </MDBRow>

          <MDBRow center className="align-items-center modal-param-row">
            <img src={done} className="fullname-active"/>
            <div className="fullname-line-active"/>
            <img src={active} className="fullname-inactive"/>
            <div className="fullname-line-inactive"/>
            <img src={inactive} className="fullname-inactive"/>
          </MDBRow>

          <MDBRow center className="align-items-center modal-param-row">
           <MDBBtn flat onClick={this.handleShowContactUs} type="btn"  className="kapcsolat"><span className="tesztvezetes-lepjen-velunk text-lowercase">vagy lépjen velünk kapcsolatba!</span></MDBBtn>
          </MDBRow>
          <MDBRow start className="">
          <form className="modal-form" onSubmit={this.handleNext}>
            <MDBRow className="align-items-center modal-form-email-row">
            <MDBCol bottom className="px-0">
              <label htmlFor="defaultFormContactNevEx" className="grey-text">
                <span className="your-name-label">Company name</span>
              </label>
              <input type="text" id="defaultFormContactNevEx" name="companyname" className="form-control" onChange={this.changeHandler} value={this.state.companyname} required />
            </MDBCol>
            </MDBRow>
            <MDBRow className="align-items-center modal-form-email-row">
              <label htmlFor="defaultFormContactTelefonszamEx" className="grey-text">
                <span className="your-name-label">Phone number</span>
              </label>
              <input type="text" id="defaultFormContactTelefonszamEx" name="phonenumber" className="form-control" onChange={this.changeHandler} value={this.state.phonenumber} required />
            </MDBRow>
						<MDBRow className="align-items-top modal-form-checkbox-row">
              <div className="custom-control custom-checkbox" >
                <input type="checkbox" className="custom-control-input" id="invalidCheck2" required />
                <label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck2">
                Elfogadom az <a id="1" href="/Nutzungsbedingungen" target="_blank">Adatkezelési Nyilatkozat</a>-ban foglaltakat.</label>
              </div>
            </MDBRow>
            <MDBRow end className="align-items-center modal-form-send-row">
              <MDBCol md="6" size="6" middle className="text-left">
                <MDBBtn flat type="btn" onClick={this.handlePrevious}>
                    <span className="previous text-capitalize">Previous</span>
                </MDBBtn>
              </MDBCol>
              <MDBCol md="6" size="6" middle className="text-right">
                <MDBBtn className="modal-btn-send-email" type="submit">
                    Next
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
          </MDBRow>
        </>
    );
  }
}

export default withTranslation()(ModalCompanyPhone);
