import React, { Component } from 'react';
import CookieConsent from "react-cookie-consent";
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import cookiesicon from "./assets/media/newicons/cookiesicon.png";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';

import LandingPage from "./pages/LandingPage";
import Tesla from "./pages/Tesla";
import Kia from "./pages/Kia";
import Hyundai from "./pages/Hyundai";
import Skoda from "./pages/Skoda";
import Volkswagen from "./pages/Volkswagen";
import NavbarComponent from './NavbarComponent';

const cookies = new Cookies();

class App extends Component {
	state = {green: false
	};

  onCookieConsent = () => {
    cookies.set('CookieConsent', 'true', {
        path: '/'
    })
  }

	onGrennTrue = () => {
		this.setState({green: true});
	}

	onGrennFalse = () => {
		this.setState({green: false});
	}


	render() {
    const { t } = this.props;
    const client = this.props.client;
		return (
			<>

			<Router>

          <NavbarComponent green = {this.state.green}/>

	          <Route path="/" exact>
	            <LandingPage {...this} {...this.props}/>
	          </Route>
	          <Route path="/Tesla" exact>
	            <Tesla {...this} {...this.props}/>
	          </Route>
	          <Route path="/Kia" exact>
	            <Kia {...this} {...this.props}/>
	          </Route>
	          <Route path="/Hyundai" exact>
	            <Hyundai {...this} {...this.props}/>
	          </Route>
	          <Route path="/Skoda" exact>
	            <Skoda {...this} {...this.props}/>
	          </Route>
	          <Route path="/Volkswagen" exact>
	            <Volkswagen {...this} {...this.props}/>
	          </Route>



    </Router>

		<CookieConsent
			location="bottom"
			disableStyles={true}
			buttonText={t('akzeptieren')}
			cookieName="myAwesomeCookieName2"
			buttonClasses="btn btn-akzeptiren-cookies"
			contentClasses="cookies-content"
			buttonWrapperClasses="cookies-btn-wrapper"
			expires={150}
		>
			<h5 className="cookies-text-title"><img src={cookiesicon}/>{t('wir_verwenden_cookies')}</h5>
			<p>{t('um_ihnen_die_bestmögliche')}</p>
                <p>{t('weitere_informationen_finden')}<a id="1" href="/Datenschutzrichtlinien" target="_blank">{t('datenschutzinformation')}</a>{t('bitte_beachten_sie')}</p>
		</CookieConsent>

</>
		);
	}
}

export default withTranslation()(App);
