import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import combined_shape from "../../assets/media/images/combined-shape.png";
import close from "../../assets/media/newicons/close.png";
import done from "../../assets/media/newicons/done.png";
import inactive from "../../assets/media/newicons/inactive.png";
import active from "../../assets/media/newicons/active.png";
import{
	MDBContainer,
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdbreact';

class ModalOrderDetails extends Component {
  state = {
						};

  changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

		handleNext = () => {
			this.props.handleShowThankYou();
			this.props.handleCloseOrderDetails();
		}

		handlePrevious = () => {
			this.props.handleShowRentalTimeInsurance();
			this.props.handleCloseOrderDetails();
		}

  render() {
    const { t } = this.props;
    const client = this.props.client;
    console.log(this.state.showEmailFullNameModal);
    return (
      <>
					<MDBRow start className="align-items-center modal-teslamodel-row">
					<MDBCol md="3" size="2" className="text-left">
					</MDBCol>
					<MDBCol md="6" size="8" className="text-center">
					 <span className="tesztvezetes-tesla-model-3">{this.props.teslamodel}</span>
					</MDBCol>
					<MDBCol md="3" size="2" className="text-right">
					 <MDBBtn flat onClick={this.props.handleClose} className="togglebtn"><img src={close} className="img-toggle-close"/></MDBBtn>
					</MDBCol>
					</MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-standard-plus">Long-term rentals</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-tesztvezetes">{this.props.monthPrice} €/months</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-kerem-adja-meg">Order Details</span>
          </MDBRow>

          <MDBRow center className="align-items-center modal-param-row">
            <img src={done} className="fullname-active"/>
            <div className="fullname-line-active"/>
            <img src={done} className="fullname-inactive"/>
            <div className="fullname-line-inactive"/>
            <img src={combined_shape} className="fullname-inactive"/>
          </MDBRow>

          <MDBRow center className="align-items-center modal-order-row">
						<span className="order-something-website">{this.props.email}</span>
          </MDBRow>
					<MDBRow center className="align-items-center modal-order-row">
						<span className="order-something-website">{this.props.fullname}</span>
          </MDBRow>
					<MDBRow center className="align-items-center modal-order-row">
						<span className="order-something-website">{this.props.companyname}</span>
          </MDBRow>
					<MDBRow center className="align-items-center modal-order-row">
						<span className="order-something-website">{this.props.phonenumber}</span>
          </MDBRow>
					<MDBRow center className="align-items-center modal-order-row-grun">
					<MDBCol middle md="6" size="12" className="text-center">
						<span className="order-long-term">{this.props.rentaltime}</span>
					</MDBCol>
					<MDBCol middle md="6" size="12" className="text-center">
						<span className="order-long-term">{this.props.insurance}</span>
					</MDBCol>
          </MDBRow>
          <MDBRow start className="">
					<form className="modal-form" onSubmit={this.handleNext}>
						<MDBRow className="align-items-top modal-form-checkbox-row">
							<div className="custom-control custom-checkbox">
								<input type="checkbox" className="custom-control-input" id="invalidCheck2" required />
								<label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck2">
								Elfogadom az <a id="1" href="/Nutzungsbedingungen" target="_blank">Adatkezelési Nyilatkozat</a>-ban foglaltakat.</label>
							</div>
						</MDBRow>
						<MDBRow end className="align-items-center modal-form-send-row">
							<MDBCol md="6" size="6" middle className="text-left">
								<MDBBtn flat type="btn" onClick={this.handlePrevious}>
										<span className="previous text-capitalize">Previous</span>
								</MDBBtn>
							</MDBCol>
							<MDBCol md="6" size="6" middle className="text-right">
								<MDBBtn className="modal-btn-send-email" type="submit">
										Next
								</MDBBtn>
							</MDBCol>
						</MDBRow>
					</form>
          </MDBRow>
        </>
    );
  }
}

export default withTranslation()(ModalOrderDetails);
