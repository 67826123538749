import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import './assets/scss/style.scss';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <Suspense fallback={<div>Laden...</div>}>
    <App/>
  </Suspense>

  ,
  document.getElementById('root'));

registerServiceWorker();
