import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import combined_shape from "../../assets/media/images/combined-shape.png";
import close from "../../assets/media/newicons/close.png";
import done from "../../assets/media/newicons/done.png";
import inactive from "../../assets/media/newicons/inactive.png";
import active from "../../assets/media/newicons/active.png";
import ModalCompanyPhone from "./ModalCompanyPhone";
import ModalContactUs from "./ModalContactUs";
import ModalEmailFullName from "./ModalEmailFullName";
import ModalOrderDetails from "./ModalOrderDetails";
import ModalRentalTimeInsurance from "./ModalRentalTimeInsurance";
import ModalThankYou from "./ModalThankYou";
import ModalTesztvezetes from "./ModalTesztvezetes";
import{
	MDBModal,
	MDBModalBody,
	MDBContainer,
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdbreact';

class MusterModal extends Component {
  state = { showCompanyPhoneModal: false,
            showContactUsModal: false,
            showEmailFullNameModal: this?.props.showEmailFullNameModal ? this.props.showEmailFullNameModal : false,
            showTesztvezetesModal: this?.props.showTesztvezetesModal ? this.props.showTesztvezetesModal : false,
            show: this?.props.show ? this.props.show : false,
            showOrderDetailsModal: false,
            showRentalTimeInsuranceModal: false,
            showThankYouModal: false,
            email: "",
            fullname: "",
            companyname: "",
            phonenumber: "",
            renataltime: "",
            insurance: ""
						};

  setEmailFullname = (email, fullname, modalnev) => {
    this.setState({email: email, fullname: fullname, modalnev: modalnev});

  }

  setCompanynamePhonenumber = (companyname, phonenumber, modalnev) => {
    this.setState({companyname: companyname, phonenumber: phonenumber, modalnev: modalnev});
    console.log(modalnev);
  }

  setRentalTimeInsurance = (rentaltime, insurance, monthPrice, modalnev) => {
    this.setState({rentaltime: rentaltime, insurance: insurance, monthPrice: monthPrice, modalnev: modalnev});
  }

  setTesztvezetes = (nev, telefonszam, modalnev) => {
		this.setState({nev: nev, telefonszam: telefonszam, modalnev: modalnev});
	}

  handleShowCompanyPhone = () => {
    this.setState({showCompanyPhoneModal: !this.state.showCompanyPhoneModal});
  }

  handleCloseCompanyPhone = () => {
    this.setState({showCompanyPhoneModal: !this.state.showCompanyPhoneModal});
  }

	handleShowContactUs = () => {
    this.setState({showContactUsModal: !this.state.showContactUsModal});
  }

  handleCloseContactUs = () => {
    this.setState({showContactUsModal: !this.state.showContactUsModal});
  }

	handleShowEmailFullName = () => {
    this.setState({showEmailFullNameModal: !this.state.showEmailFullNameModal});
  }

  handleCloseEmailFullName = () => {
    this.setState({showEmailFullNameModal: !this.state.showEmailFullNameModal});
  }

	handleShowOrderDetails = () => {
    this.setState({showOrderDetailsModal: !this.state.showOrderDetailsModal});
  }

  handleCloseOrderDetails = () => {
    this.setState({showOrderDetailsModal: !this.state.showOrderDetailsModal});
  }

	handleShowRentalTimeInsurance = () => {
    this.setState({showRentalTimeInsuranceModal: !this.state.showRentalTimeInsuranceModal});
  }

  handleCloseRentalTimeInsurance = () => {
    this.setState({showRentalTimeInsuranceModal: !this.state.showRentalTimeInsuranceModal});
  }

	handleShowThankYou = () => {
    this.setState({showThankYouModal: !this.state.showThankYouModal});
  }

  handleCloseThankYou = () => {
    this.setState({showThankYouModal: !this.state.showThankYouModal});
  }

  handleShowTesztvezetes = () => {
    this.setState({showTesztvezetesModal: !this.state.showTesztvezetesModal});
  }

  handleCloseTesztvezetes = () => {
    this.setState({showTesztvezetesModal: !this.state.showTesztvezetesModal});
  }
  render() {
    const { t } = this.props;
    const client = this.props.client;
    console.log(this.state.modalnev);
    return (
      <>
        <MDBContainer>
          <MDBModal isOpen={this.props.show} toggle={this.props.handleClose} id="tesztvezetes" >
            <MDBModalBody>
            {this.state.showCompanyPhoneModal ?
              <ModalCompanyPhone
                handleCloseCompanyPhone={this.handleCloseCompanyPhone}
                handleClose={this.props.handleClose}
                handleShowContactUs={this.handleShowContactUs}
                handleShowEmailFullName={this.handleShowEmailFullName}
                handleShowRentalTimeInsurance={this.handleShowRentalTimeInsurance}
                setCompanynamePhonenumber={this.setCompanynamePhonenumber}
                teslamodel={this.props.teslamodel}
                email={this.state.email}
                fullname={this.state.fullname}
                companyname={this.state.companyname}
                phonenumber={this.state.phonenumber}
                rentaltime={this.state.rentaltime}
                insurance={this.state.insurance}/>:
            false}
            {this.state.showContactUsModal ?
              <ModalContactUs
                modalnev={this.state.modalnev}
                handleCloseContactUs={this.handleCloseContactUs}
                handleClose={this.props.handleClose}
                handleShowCompanyPhone={this.handleShowCompanyPhone}
                handleShowEmailFullName={this.handleShowEmailFullName}
                handleShowRentalTimeInsurance={this.handleShowRentalTimeInsurance}
                handleShowTesztvezetes={this.handleShowTesztvezetes}
                teslamodel={this.props.teslamodel}
                email={this.state.email}
                fullname={this.state.fullname}
                companyname={this.state.companyname}
                phonenumber={this.state.phonenumber}
                rentaltime={this.state.rentaltime}
                insurance={this.state.insurance}
                />:
            false}
            {this.state.showEmailFullNameModal ?
              <ModalEmailFullName
                setEmailFullname={this.setEmailFullname}
                handleCloseEmailFullName={this.handleCloseEmailFullName}
                handleClose={this.props.handleClose}
                handleShowCompanyPhone={this.handleShowCompanyPhone}
                handleShowContactUs={this.handleShowContactUs}
                teslamodel={this.props.teslamodel}
                email={this.state.email}
                fullname={this.state.fullname}
                companyname={this.state.companyname}
                phonenumber={this.state.phonenumber}
                renataltime={this.state.renataltime}
                insurance={this.state.insurance}
                email={this.state.email}
                fullname={this.state.fullname}
                companyname={this.state.companyname}
                phonenumber={this.state.phonenumber}
                rentaltime={this.state.rentaltime}
                insurance={this.state.insurance}/>:
            false}
            {this.state.showOrderDetailsModal ?
              <ModalOrderDetails
                handleCloseOrderDetails={this.handleCloseOrderDetails}
                handleClose={this.props.handleClose}
                teslamodel={this.props.teslamodel}
                handleShowRentalTimeInsurance={this.handleShowRentalTimeInsurance}
                handleShowThankYou={this.handleShowThankYou}
                monthPrice={this.state.monthPrice}
                email={this.state.email}
                fullname={this.state.fullname}
                companyname={this.state.companyname}
                phonenumber={this.state.phonenumber}
                rentaltime={this.state.rentaltime}
                insurance={this.state.insurance}/>:
            false}
            {this.state.showRentalTimeInsuranceModal ?
              <ModalRentalTimeInsurance
                handleCloseRentalTimeInsurance={this.handleCloseRentalTimeInsurance}
                handleClose={this.props.handleClose}
                handleShowCompanyPhone={this.handleShowCompanyPhone}
                handleShowContactUs={this.handleShowContactUs}
                handleShowOrderDetails={this.handleShowOrderDetails}
                setRentalTimeInsurance={this.setRentalTimeInsurance}
                teslamodel={this.props.teslamodel}
                email={this.state.email}
                fullname={this.state.fullname}
                companyname={this.state.companyname}
                phonenumber={this.state.phonenumber}
                rentaltime={this.state.rentaltime}
                insurance={this.state.insurance}/>:
            false}
            {this.state.showThankYouModal ?
              <ModalThankYou
                handleCloseThankYou={this.props.handleClose}
                handleClose={this.props.handleClose}
                handleShowOrderDetails={this.handleShowOrderDetails}
                teslamodel={this.props.teslamodel}
                email={this.state.email}/>:
            false}
            {this.state.showTesztvezetesModal ?
          		<ModalTesztvezetes
          			show={this.state.showTesztvezetesModal}
          			handleCloseTesztvezetes={this.handleCloseTesztvezetes}
                handleClose={this.props.handleClose}
          			handleShowContactUs={this.handleShowContactUs}
          			setTesztvezetes={this.setTesztvezetes}
          			nev= {this.state.nev}
          			telefonszam = {this.state.telefonszam}
          			teslamodel={this.props.teslamodel}/>:
          	false}
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </>
    );
  }
}

export default withTranslation()(MusterModal);
