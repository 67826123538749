import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import combined_shape from "../../assets/media/images/combined-shape.png";
import close from "../../assets/media/newicons/close.png";
import done from "../../assets/media/newicons/done.png";
import inactive from "../../assets/media/newicons/inactive.png";
import active from "../../assets/media/newicons/active.png";
import{
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
	MDBSelectOption,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions
} from 'mdbreact';

class ModalRentalTimeInsurance extends Component {
  state = {rentaltimeList: ["48 months (min.)", "60 months", "72 months"],
						insuranceList: ["Super Premium (+49€)"],
						rentaltime: this?.props.rentaltime ? this.props.rentaltime: "",
						insurance: this?.props.insurance ? this.props.insurance: "",
						extra: 0,
						monthPrice: 1039

						};

	onSetRentaltime = (event) => {
		this.setState({ rentaltime: event });
	}

	onSetInsurance = (event) => {
		this.setState({ insurance: event });
		console.log("hello");
		if(event == "Super Premium (+49€)"){
			console.log("hello2");
			this.setState({ extra: 49 });
		}
	}

	handleShowContactUs = () => {
		this.props.setRentalTimeInsurance(this.state.rentaltime, this.state.insurance, this.state.monthPrice, "rentaltimeinsurance")
		this.props.handleShowContactUs();
		this.props.handleCloseRentalTimeInsurance();
	}

	handleNext = () => {
		this.props.setRentalTimeInsurance(this.state.rentaltime, this.state.insurance, this.state.monthPrice, "rentaltimeinsurance")
		this.props.handleShowOrderDetails();
		this.props.handleCloseRentalTimeInsurance();
	}

	handlePrevious = () => {
		this.props.handleShowCompanyPhone(this.props.teslamodel);
		this.props.handleCloseRentalTimeInsurance();
	}

  render() {
    const { t } = this.props;
    const client = this.props.client;
		let rentaltimeLis = {};
    rentaltimeLis = this.state.rentaltimeList.map(
      (o) =>
      <div key={o}>
            {this.state.rentaltime === o ?
              <MDBSelectOption checked value={o}>{o}</MDBSelectOption>:

              <MDBSelectOption  value={o}>{o}</MDBSelectOption>
            }
      </div>
    );
		let insuranceLis = {};
    insuranceLis = this.state.insuranceList.map(
      (o) =>
      <div key={o}>
            {this.state.insurance === o ?
              <MDBSelectOption checked value={o}>{o}</MDBSelectOption>:

              <MDBSelectOption  value={o}>{o}</MDBSelectOption>
            }
      </div>
    );
		let price = 990;
		console.log(this.state.extra);
    return (
      <>
					<MDBRow start className="align-items-center modal-teslamodel-row">
					<MDBCol md="3" size="2" className="text-left">
					</MDBCol>
					<MDBCol md="6" size="8" className="text-center">
					 <span className="tesztvezetes-tesla-model-3">{this.props.teslamodel}</span>
					</MDBCol>
					<MDBCol md="3" size="2" className="text-right">
					 <MDBBtn flat onClick={this.props.handleClose} className="togglebtn"><img src={close} className="img-toggle-close"/></MDBBtn>
					</MDBCol>
					</MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-standard-plus">Long-term rentals</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-tesztvezetes">{price + this.state.extra} €/months</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-kerem-adja-meg">Please fill out the form</span>
          </MDBRow>

          <MDBRow center className="align-items-center modal-param-row">
            <img src={done} className="fullname-active"/>
            <div className="fullname-line-active"/>
            <img src={done} className="fullname-active"/>
            <div className="fullname-line-active"/>
            <img src={active} className="fullname-active"/>
          </MDBRow>

					<MDBRow center className="align-items-center modal-param-row">
           <MDBBtn flat onClick={this.handleShowContactUs} type="btn"  className="kapcsolat"><span className="tesztvezetes-lepjen-velunk text-lowercase">vagy lépjen velünk kapcsolatba!</span></MDBBtn>
          </MDBRow>
          <MDBRow start className="">
          <form className="modal-form" onSubmit={this.handleNext}>
            <MDBRow className="align-items-center modal-form-dropdown-row">
            <MDBCol bottom className="px-0">
							<MDBSelect label="Rental time" getTextContent={this.onSetRentaltime} selected="Válasszon futamidöt!" id="insurance">
								<MDBSelectInput selected="Válasszon futamidöt!" id="insurance"/>
								<MDBSelectOptions id="insurance">

									{rentaltimeLis}
								</MDBSelectOptions>
							</MDBSelect>
            </MDBCol>
            </MDBRow>
						<MDBRow className="align-items-center modal-form-dropdown-row">
            <MDBCol bottom className="px-0">
							<MDBSelect label="Insurance" getTextContent={this.onSetInsurance} selected="Válasszon kiegészítöket!" id="insurance">
								<MDBSelectInput selected="Válasszon kiegészítöket!" />
								<MDBSelectOptions>

									{insuranceLis}
								</MDBSelectOptions>
							</MDBSelect>
            </MDBCol>
            </MDBRow>
            <MDBRow center className="align-items-center modal-form-send-row">
              <MDBCol md="6" size="6" middle className="text-left">
                <MDBBtn flat type="btn" onClick={this.handlePrevious}>
                    <span className="previous text-capitalize">Previous</span>
                </MDBBtn>
              </MDBCol>
              <MDBCol md="6" size="6" middle className="text-right">
                <MDBBtn className="modal-btn-send-email" type="submit">
                    Next
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>

          </MDBRow>
        </>
    );
  }
}

export default withTranslation()(ModalRentalTimeInsurance);
