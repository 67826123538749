import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import combined_shape from "../../assets/media/images/combined-shape.png";
import close from "../../assets/media/newicons/close.png";
import done from "../../assets/media/newicons/done.png";
import inactive from "../../assets/media/newicons/inactive.png";
import active from "../../assets/media/newicons/active.png";
import{
	MDBModal,
	MDBModalBody,
	MDBContainer,
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdbreact';

class ModalEmailFullName extends Component {
  state = {	email: this?.props.email ? this.props.email: "",
						fullname: this?.props.fullname ? this.props.fullname: "",
						};


  changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };


	handleShowContactUs = () => {
		this.props.setEmailFullname(this.state.email, this.state.fullname, "emailfullname")
		this.props.handleShowContactUs();
		this.props.handleCloseEmailFullName();
	}

	handleNext = () => {
		this.props.setEmailFullname(this.state.email, this.state.fullname, "emailfullname")
		this.props.handleShowCompanyPhone();
		this.props.handleCloseEmailFullName();
	}

  render() {
    const { t } = this.props;
    const client = this.props.client;
    return (
      <>
					<MDBRow start className="align-items-center modal-teslamodel-row">
					<MDBCol md="3" size="2" className="text-left">
					</MDBCol>
					<MDBCol md="6" size="8" className="text-center">
					 <span className="tesztvezetes-tesla-model-3">{this.props.teslamodel}</span>
					</MDBCol>
					<MDBCol md="3" size="2" className="text-right">
					 <MDBBtn flat onClick={this.props.handleClose} className="togglebtn"><img src={close} className="img-toggle-close"/></MDBBtn>
					</MDBCol>
					</MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-standard-plus">Long-term rentals</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-tesztvezetes">990 €/months</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="tesztvezetes-kerem-adja-meg">Please fill out the form</span>
          </MDBRow>

          <MDBRow center className="align-items-center modal-param-row">
            <img src={active} className="fullname-active"/>
            <div className="fullname-line-inactive"/>
            <img src={inactive} className="fullname-inactive"/>
            <div className="fullname-line-inactive"/>
            <img src={inactive} className="fullname-inactive"/>
          </MDBRow>

          <MDBRow center className="align-items-center modal-param-row">
           <MDBBtn flat onClick={this.handleShowContactUs} type="btn"  className="kapcsolat"><span className="tesztvezetes-lepjen-velunk text-lowercase">vagy lépjen velünk kapcsolatba!</span></MDBBtn>
          </MDBRow>
          <MDBRow start className="">
          <form className="modal-form" onSubmit={this.handleNext}>
            <MDBRow className="align-items-center modal-form-email-row">
            <MDBCol bottom className="px-0">
              <label htmlFor="defaultFormContactNevEx" className="grey-text">
                <span className="your-name-label">Email address</span>
              </label>
              <input type="email" id="defaultFormContactNevEx" name="email" className="form-control" onChange={this.changeHandler} value={this.state.email} required />
            </MDBCol>
            </MDBRow>
            <MDBRow className="align-items-center modal-form-email-row">
              <label htmlFor="defaultFormContactTelefonszamEx" className="grey-text">
                <span className="your-name-label">Full name</span>
              </label>
              <input type="text" id="defaultFormContactTelefonszamEx" name="fullname" className="form-control" onChange={this.changeHandler} value={this.state.fullname} required />
            </MDBRow>
            <MDBRow end className="align-items-center  modal-form-send-row" >
              <MDBBtn className="modal-btn-send-email" type="submit">
                  Next
              </MDBBtn>
            </MDBRow>
          </form>
          </MDBRow>
        </>
    );
  }
}

export default withTranslation()(ModalEmailFullName);
