import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../../assets/scss/modules/landing.module.scss';
import tesla_fantom from "../../assets/media/images/Tesla-Fantom.png";
import tesla_mask from "../../assets/media/images/Tesla-Mask.png";
import combined_shape from "../../assets/media/images/combined-shape.png";
import back from "../../assets/media/newicons/back.png";
import arrow from "../../assets/media/newicons/arrow.png";
import arrowWhite from "../../assets/media/newicons/arrowWhite.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBIcon
} from 'mdbreact';

class BestOfferModul extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
            <>
                <MDBRow center>
                  <p className="tesla-model-text text-center">{this.props.msg.manufacturer + " " + this.props.msg.model}</p>
                </MDBRow>

                <MDBRow center>
                  <img src={this.props.msg.urlName} className="tesla-model-3-modul"/>
                </MDBRow>

                  <div className="tesla-model-daten-row">
                    {this.props.msg.range !== 0 ?
                    <MDBRow >
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">Range</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.range + " km"}</span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="3">
                      </MDBCol>
                    </MDBRow>:false}

                    {this.props.msg.acceleration_to_100 !== 0 ?
                    <MDBRow>
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">0-100 km/h</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.acceleration_to_100 + " s"}</span>
                          </MDBCol>
                        </MDBRow>
                        </MDBCol>
                        <MDBCol size="3">
                        </MDBCol>
                    </MDBRow>:false}

                    {this.props.msg.dualMotor !== null ?
                    <MDBRow>
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">Dual motor</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.dualMotor}</span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="3">
                      </MDBCol>
                    </MDBRow>:false}

                    {this.props.msg.acceleration_quarter_mile !== 0 ?
                    <MDBRow>
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">1/4 miles</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.acceleration_quarter_mile + " s"}</span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="3">
                      </MDBCol>
                    </MDBRow>:false}

                    {this.props.msg.top_speed_kmh !== 0 ?
                    <MDBRow>
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">Speed</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.top_speed_kmh + " km/h"}</span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="3">
                      </MDBCol>
                    </MDBRow>:false}

                    {this.props.msg.storage !== 0 ?
                    <MDBRow>
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">Storage</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.storage + " l"}</span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="3">
                      </MDBCol>
                    </MDBRow>:false}

                    {this.props.msg.power_hp !== 0 ?
                    <MDBRow>
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">Power</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.power_hp + " LE"}</span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="3">
                      </MDBCol>
                    </MDBRow>:false}

                    {this.props.msg.battery_kwh !== 0 ?
                    <MDBRow>
                      <MDBCol size="3">
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBRow>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-daten">Battery</span>
                          </MDBCol>
                          <MDBCol md="6" size="6" className="nopadding-nomargin-col">
                            <span className="tesla-param">{this.props.msg.battery_kwh + " kwh"}</span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="3">
                      </MDBCol>
                    </MDBRow>:false}
                  </div>


                <MDBRow center className="tesla-rectangle-padding-bottom">
									<div className="tesla-rectangle-2 ">
										<MDBRow>
											<MDBCol md="6" size="7">
                        <MDBRow className="lease-row">
                          <span className="best-offer">Best Offer</span>
                        </MDBRow>
												<MDBRow start className="lease-row">
													<span className="long-term-rental">Long-term rental</span>
												</MDBRow>
											</MDBCol>
											<MDBCol md="6" size="5">
												<MDBRow end className="get-an-offer-row">
													<span className="price-€-month">990 €/m</span>
												</MDBRow>
												<MDBRow end className="get-an-offer-row">
													<MDBBtn
	                            flat
	                            className="tesla-arrow-btn"
	                            type="btn"
	                            onClick={() => this.props.handleShow(this.props.msg.manufacturer + " " + this.props.msg.model, false)}
	                            >
	                            <span className="get-an-offer-w text-capitalize">Get an Offer</span><img src={arrowWhite} className="arrow"/>
	                        </MDBBtn>
												</MDBRow>
											</MDBCol>
										</MDBRow>
									</div>
							</MDBRow>
            </>
    );
  }
}
export default  withTranslation()(BestOfferModul);
