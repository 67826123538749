import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import close from "../../assets/media/newicons/close.png";
import back from "../../assets/media/newicons/back.png";
import logo from '../../assets/media/newicons/logo.png';
import{
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdbreact';

class ModalContactUs extends Component {
  state = {
						};


  setModalShow = () => {
    this.props.show();
  };

  setModalClose = () => {
    this.props.handleClose();
  };

  changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

handleSend = (event) => {
 let self = this;
 event.preventDefault();
 axios.post(process.env.REACT_APP_BACKEND_URL+'/send',
     {email: this.state.email,
      subject: "Contact Us",
      message: this.state.message},
  function(response) {
		this.props.handleCloseContactUs();
  });
}


 handleCloseModal = () => {
	 if(this.props.modalnev === "tesztvezetes"){
		 this.props.handleShowTesztvezetes();
	 }
	 if(this.props.modalnev === "companyphone"){
		 this.props.handleShowCompanyPhone();
	 }
	 if(this.props.modalnev === "emailfullname"){
		 this.props.handleShowEmailFullName();
	 }
	 if(this.props.modalnev === "rentaltimeinsurance"){
		 this.props.handleShowRentalTimeInsurance();
	 }
	 this.props.handleCloseContactUs();
 }

  render() {
    const { t } = this.props;
    const client = this.props.client;
    console.log(this.props.modalnev);
    return (
      <>
          <MDBRow start className="align-items-center modal-teslamodel-row">
            <MDBCol md="3" size="3" className="text-left">
             <MDBBtn flat onClick={this.handleCloseModal} className="backbtn"><img src={back} className="tesla-back-img"/></MDBBtn>
            </MDBCol>
            <MDBCol md="6" size="6" className="text-center">
             <img src={logo} className="contactus-logo"/>
            </MDBCol>
            <MDBCol md="3" size="3" className="text-right">
             <MDBBtn flat onClick={this.props.handleClose} className="togglebtn-contactus"><img src={close} className="img-toggle-close"/></MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="align-items-center modal-contact-row">
            <span className="contactus-text">Contact us</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="contactus-if-you-have">If you have any questions feel free to contact us.</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-contact-info-row">
            <MDBCol md="6" size="12" middle>
              <MDBRow center>
                <span className="tesztvezetes-lepjen-velunk text-lowercase">info@grunschock.hu</span>
              </MDBRow>
            </MDBCol>
            <MDBCol md="6" size="12" middle>
              <MDBRow center>
                <span className="tesztvezetes-lepjen-velunk text-lowercase">+36 70 600 4 500</span>
              </MDBRow>

            </MDBCol>
          </MDBRow>
          <MDBRow start className="">
          <form className="modal-form" onSubmit={this.handleSend}>
            <MDBRow className="align-items-center modal-form-email-row">
            <MDBCol bottom className="px-0">
            <label htmlFor="defaultFormContactEmail2Ex" className="grey-text">
              <span className="your-email-label">Your Email Address</span>
            </label>
            <input type="email" id="defaultFormContactEmail2Ex" name="email" className="form-control" onChange={this.changeHandler} value={this.state.email} required/>
            </MDBCol>
            </MDBRow>
            <MDBRow className="align-items-center modal-form-email-row">
            <label htmlFor="defaultFormContactMessage2Ex" className="grey-text">
              <span className="your-email-label">Message</span>
            </label>
            <textarea type="text" id="defaultFormContactMessage2Ex" name="message" className="form-control" rows="3" onChange={this.changeHandler} value={this.state.message} required/>
            </MDBRow>
            <MDBRow className="align-items-top modal-form-checkbox-row">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="invalidCheck" required />
                <label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck">
                Elfogadom az <a id="1" href="/Nutzungsbedingungen" target="_blank">Adatkezelési Nyilatkozat</a>-ban foglaltakat.</label>
              </div>
            </MDBRow>
            <MDBRow center className=" modal-form-send-row">
              <MDBBtn className="landing-btn-send-email" type="submit">
                  Send
              </MDBBtn>
            </MDBRow>
          </form>

          </MDBRow>

        </>
    );
  }
}

export default withTranslation()(ModalContactUs);
