import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../assets/scss/modules/landing.module.scss';
import landing_tesla from "../assets/media/images/landing-tesla.png";
import accumulator from "../assets/media/images/accumulator.png";
import atmosphere from "../assets/media/images/atmosphere.png";
import biofuel from "../assets/media/images/biofuel.png";
import battery from "../assets/media/images/battery.png";
import electric_car from "../assets/media/images/electric-car.png";
import electric_tower from "../assets/media/images/electric-tower.png";
import carE_1 from "../assets/media/images/carE-1.png";
import carE_2 from "../assets/media/images/carE.png";
import wind from "../assets/media/images/wind.png";
import minta_akku from "../assets/media/images/minta-akku.png";
import minta_akku_w from "../assets/media/images/minta-akku-w.png";
import tesla_logo from "../assets/media/newicons/autos/tesla.png";
import kia_logo from "../assets/media/newicons/autos/kia.png";
import hyundai_logo from "../assets/media/newicons/autos/hyundai.png";
import skoda_logo from "../assets/media/newicons/autos/skoda.png";
import volkswagen_logo from "../assets/media/newicons/autos/volkswagen.png";
import arrowWhite_down from "../assets/media/newicons/arrowWhite_down.png";
import arrowWhite_up from "../assets/media/newicons/arrowWhite_up.png";
import Cookies from 'universal-cookie';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../components/Footer/Footer';
import MusterModal from "../components/Modal/MusterModal";
import BestOfferModul from "../components/Cars/BestOfferModul";
import GyikModul from "../components/Gyik/GyikModul";
import GrunschockAxios from '../Service/GrunschockAxios';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBIcon,
	MDBCollapseHeader,
	MDBCollapse,
} from 'mdbreact';
import axios from 'axios';

const cookies = new Cookies();

class LandingPage extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new GrunschockAxios(this);
    this.state = {showModal: false,
			 						nev: "", telefonszam: "",
									automodelList1: [],
									automodelList2: [],
									automodelList3: [],
									automodelList4: [],
									gyikList: [],
									collapseID: -1
									};
  };

	componentDidMount() {
		this.handlegetTesla();
		this.handlegetAllGyik();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
		this.props.onGrennFalse();
	}

	handlegetTesla = () => {
	 let self = this;
	 this.interAxios.get('/getautomodels',
		function(response) {

			let list = response.data
			let list1 = self.getRandom(list, 2);
			var res1 = list.filter(item1 =>
					!list1.some(item2 => (item2.id === item1.id)))
			let list2 = self.getRandom(res1, 2);
			var res2 = res1.filter(item1 =>
					!list2.some(item2 => (item2.id === item1.id)))
			let list3 = self.getRandom(res2, 2);
			var res3 = res2.filter(item1 =>
					!list3.some(item2 => (item2.id === item1.id)))
			let list4 = self.getRandom(res3, 2);
			self.setState({ automodelList1: list1,
											automodelList2: list2,
										  automodelList3: list3,
										  automodelList4: list4,})
		});
	}

	handlegetAllGyik = () => {
	 let self = this;
	 this.interAxios.get('/getallgyik',
		function(response) {
			self.setState({gyikList: response.data})
		});
	}

	handleNewGyik = (event) => {
	 let self = this;
	 event.preventDefault();
	 this.interAxios.post('/newgyik',
			 {kerdes: "Lorem Ipsum Dolor?",
				valasz: "Pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch."},
		function(response) {

		});
	}

	changeHandler = event => {
	    this.setState({ [event.target.name]: event.target.value });
	  };

	 handleSend = (event) => {
    let self = this;
		event.preventDefault();
    axios.post(process.env.REACT_APP_BACKEND_URL+'/send',
        {email: this.state.email,
         subject: this.state.subject,
         message: this.state.message},
     function(response) {

     });
	 }

	handleShow = (model, tesztvezetes) => {
		this.setState({teslamodel: model, showModal: !this.state.showModal, tesztvezetesmodal: tesztvezetes});
	}

	handleClose= () => {
		this.setState({showModal: !this.state.showModal});
	}

	toggleCollapse = collapseID => () =>
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));

	getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		const { collapseID } = this.state;
		const anchors = ["firstPage", "secondPage", "thirdPage", "fourthPage", "fifthPage", "lastPage"];
		const navigationTooltips = ["HOME", "OUR SERVICE", "GUARANTEES", "ABOUT US", "KAPCSOLAT", "GYIK"];
		let termeklist1 = [];
		if (this.state.automodelList1.length > 0){
		termeklist1 = this.state.automodelList1.map(
			(o) => {
					return (
						<BestOfferModul
													 key={o.id}
													 msg={o}
													 handleShow= {this.handleShow2}
													 handleClose= {this.handleClose2}
													 handleNewTesla= {this.handleNewTesla}
													/>
									);
		});}
		let termeklist2 = [];
		if (this.state.automodelList2.length > 0){
		termeklist2 = this.state.automodelList2.map(
			(o) => {
					return (
						<BestOfferModul
													 key={o.id}
													 msg={o}
													 handleShow= {this.handleShow2}
													 handleClose= {this.handleClose2}
													 handleNewTesla= {this.handleNewTesla}
													/>
									);
		});}
		let termeklist3 = [];
		if (this.state.automodelList3.length > 0){
		termeklist3 = this.state.automodelList3.map(
			(o) => {
					return (
						<BestOfferModul
													 key={o.id}
													 msg={o}
													 handleShow= {this.handleShow2}
													 handleClose= {this.handleClose2}
													 handleNewTesla= {this.handleNewTesla}
													/>
									);
		});}
		let termeklist4 = [];
		if (this.state.automodelList4.length > 0){
		termeklist4 = this.state.automodelList4.map(
			(o) => {
					return (
						<BestOfferModul
													 key={o.id}
													 msg={o}
													 handleShow= {this.handleShow2}
													 handleClose= {this.handleClose2}
													 handleNewTesla= {this.handleNewTesla}
													/>
									);
		});}
		let gyiklist = [];
		if (this.state.gyikList.length > 0){
		gyiklist = this.state.gyikList.map(
			(o) => {
					return (<GyikModul key={o.id} msg={o} toggleCollapse={this.toggleCollapse} collapseID={collapseID}/>);
		});}
    return (
			<>

			<ReactFullpage
    //fullpage options
    licenseKey = {'2C01A6F9-2F35483E-81C14CF4-C7FF7A52'}
    scrollingSpeed = {500} /* Options here */
		anchors={anchors}
		menu='#myMenu'
		navigation={true}
		navigationTooltips={navigationTooltips}
		navigationPosition={'right'}
		showActiveTooltip= {true}
		responsiveWidth={800}
		responsiveHeight={750}
		onLeave={(origin,destination,direction) => {
			if(destination.index === 1){
				this.props.onGrennTrue();
			}else{
				this.props.onGrennFalse();
			}
			//console.log(origin.index + ' ' + destination.index + ' ' + direction);
		}}


    render={({ state, fullpageApi }) => {
      return (
				<>

        <ReactFullpage.Wrapper>
					<MDBContainer fluid className="section fluid-container">
						<div className={styles.bodywrapper}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow >
									<MDBCol size="12" className="pl-4 text-left" md="6" middle>
										<MDBRow start className="landing-e-fleet-row">
											<span className="e-fleet-management">Elektromos autó <span style={{color: "#434343", }}>Flottakezelés</span></span>
										</MDBRow>
										<MDBRow start className="landing-e-fleet-row1">
											<span className="e-fleet-management-l e-fleet-management-grun">Stock New</span>
										</MDBRow>
										<MDBRow center className="landing-e-fleet-row2">
											<span className="e-fleet-management-c e-fleet-management-grun">&</span>
										</MDBRow>
										<MDBRow end className="landing-e-fleet-row3">
											<span className="e-fleet-management-r e-fleet-management-grun">Demo Cars</span>
										</MDBRow>
										<div  className="d-md-none d-block pt-4">
										<MDBRow center className="landing-auto-logo-row">
											<MDBCol size="3" md="4" className="pl-4 pr-2 text-center">
												<div className="rectangle-autos">
												<Link to="/Tesla">
													<span className="rectangle-autos-name">Tesla</span>
												</Link>
												</div>
											</MDBCol>
											<MDBCol size="3" md="4" className="px-2 text-center">
												<div className="rectangle-autos">
												<Link to="/Skoda">
													<span className="rectangle-autos-name">Skoda</span>
												</Link>
												</div>
											</MDBCol>
											<MDBCol size="6" md="4" className="pl-3 pr-4 text-center">
												<div className="rectangle-autos">
												<Link to="/Volkswagen">
													<span className="rectangle-autos-name">Volkswagen</span>
												</Link>
												</div>
											</MDBCol>
										</MDBRow>
										<MDBRow center className="landing-auto-logo-row">
											<MDBCol size="6" md="6" className="pl-5 pr-3 text-center">
												<div className="rectangle-autos">
												<Link to="/Kia">
													<span className="rectangle-autos-name">Kia</span>
												</Link>
												</div>
											</MDBCol>
											<MDBCol size="6" md="4" className="pr-5 pl-3 text-center">
												<div className="rectangle-autos">
												<Link to="/Hyundai">
													<span className="rectangle-autos-name">Hyundai</span>
												</Link>
												</div>
											</MDBCol>
										</MDBRow>
										</div>
										<MDBRow center className="landing-link-btn-tesztvezetes-row">
										<Link to="/Tesla">
										<MDBRow center className="landing-link-row">
											<MDBCol md="5" size="12" className="p-0 text-center landing-tesla-col"  middle>
												<img className="landing-tesla" src={landing_tesla}></img>
											</MDBCol>
											<MDBCol md="7" size="12" className="p-0 text-center landing-checkout-col" middle>
												<span className="landing-long-term-rental">Check out this Tesla Model 3!</span><br/><span className="landing-long-term-rental">Long-term rental</span><br/><span className="landing-tesla-from">From </span><span className="landing-tesla-price">990.0 €</span>
											</MDBCol>
										</MDBRow>
										</Link>
											<MDBCol md="4" size="12" className="pb-4 text-center" middle>
												<MDBBtn
														className="tesztvezetes-btn-landing"
														type="btn"
														onClick={() => this.handleShow("Tesla Model 3", "Tesztvezetes")}
														>
														Tesztvezetés
												</MDBBtn>
											</MDBCol>
										</MDBRow>
									</MDBCol>
									<MDBCol md="6">
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</div>
					</MDBContainer>

					<div className={styles.bodywrapper} className="d-md-none d-block pt-3">
						<MDBContainer style={{maxWidth: "1430px"}}>
							{termeklist1}
						</MDBContainer>
					</div>

					<MDBContainer fluid className="section fluid-container">
						<div className={styles.bodywrapper2}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow className="landing-section2-row">
									<MDBCol md="6" size="12" middle className="left-col">
										<MDBRow className="landing-rental-row">
											<span className="rental">Flottakezelés</span>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2" size="4" middle className="landing-col-icon">
											<img src={accumulator} className="accumulator"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-w">Lorem ipsum dolor</span><br/>
											<span className="lorem-w-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2" size="4" middle className="landing-col-icon">
											<img src={atmosphere} className="atmosphere"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-w">Lorem ipsum dolor</span><br/>
											<span className="lorem-w-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2"  size="4" middle className="landing-col-icon">
											<img src={biofuel} className="biofuel"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-w">Lorem ipsum dolor</span><br/>
											<span className="lorem-w-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-e-car-img">
										<MDBCol bottom>
										<div className="fix-height"/>
										<img src={carE_1} className="car-e-1"/>
										</MDBCol>
										</MDBRow>
									</MDBCol>
									<MDBCol md="6" size="12" middle className="right-col">
										<MDBRow className="landing-rental-row">
											<span className="services">Szolgáltatások</span>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2" size="4" middle className="landing-col-icon">
											<img src={electric_tower} className="electric-tower"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-b">Lorem ipsum dolor</span><br/>
											<span className="lorem-b-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2"  size="4" middle className="landing-col-icon">
											<img src={electric_car} className="electric-car"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-b">Lorem ipsum dolor</span><br/>
											<span className="lorem-b-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2"  size="4" middle className="landing-col-icon">
											<img src={battery} className="battery"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-b">Lorem ipsum dolor</span><br/>
											<span className="lorem-b-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBCol middle>
										<MDBRow center className="landing-wind-img">
										<img src={wind} className="wind"/>
										</MDBRow>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</div>
					</MDBContainer>

					<div className={styles.bodywrapper} className="d-md-none d-block pt-3">
						<MDBContainer style={{maxWidth: "1430px"}}>
							{termeklist2}
						</MDBContainer>
					</div>



				<MDBContainer fluid className="section fluid-container">
				<div className={styles.bodywrapper3}>
				<MDBContainer style={{maxWidth: "1430px"}}>
					<MDBRow className="landing-section3-row">
						<span className="in-safe">In Safe</span>
					</MDBRow>
					<MDBRow>
						<MDBCol md="4" size="12" middle>
							<MDBRow className="landing-in-save-row">
							<span className="in-safe-top">Lorem Ipsum Dolor</span>
							</MDBRow>
							<MDBRow className="in-safe-middle-row">
							<span className="in-safe-middle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit.<br/><br/>
							Praesent accumsan vulputate magna, eget ullamcorper tellus eleifend et. Nunc volutpat mauris in felis pellentesque feugiat. Ut ut odio vel ex congue dapibus. Vestibulum non libero tellus. Integer efficitur felis quis libero venenatis, vitae aliquam quam finibus. Duis gravida dictum justo, maximus consectetur leo consequat sit amet.</span>
							</MDBRow>
							<MDBRow className="in-safe-icon">
							<MDBCol md="4" size="4" middle>
							<MDBRow start>
							<img src={minta_akku} className="minta-akku"/>
							</MDBRow>
							</MDBCol>
							<MDBCol md="4" size="4" middle>
							<MDBRow center>
							<img src={minta_akku} className="minta-akku d-none d-md-block"/>
							<img src={minta_akku_w} className="minta-akku d-md-none d-block"/>
							</MDBRow>
							</MDBCol>
							<MDBCol md="4" size="4" middle>
							<MDBRow end>
							<img src={minta_akku} className="minta-akku"/>
							</MDBRow>
							</MDBCol>
							</MDBRow>
							<MDBRow className="in-safe-bottom-row">
							<span className="in-safe-bottom">Praesent accumsan vulputate magna, eget ullamcorper tellus eleifend et. Nunc volutpat mauris in felis pellentesque feugiat.</span>
							</MDBRow>
						</MDBCol>
						<MDBCol md="4" size="12" middle>
							<MDBRow className="landing-in-save-row">
							<span className="in-safe-top">Lorem Ipsum Dolor</span>
							</MDBRow>
							<MDBRow className="in-safe-middle-row">
							<span className="in-safe-middle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit.<br/><br/>
							Praesent accumsan vulputate magna, eget ullamcorper tellus eleifend et. Nunc volutpat mauris in felis pellentesque feugiat. Ut ut odio vel ex congue dapibus. Vestibulum non libero tellus. Integer efficitur felis quis libero venenatis, vitae aliquam quam finibus. Duis gravida dictum justo, maximus consectetur leo consequat sit amet.</span>
							</MDBRow>
							<MDBRow className="in-safe-icon">
							<MDBCol md="4" size="4" middle>
							<MDBRow start>
							<img src={minta_akku} className="minta-akku d-none d-md-block"/>
							<img src={minta_akku_w} className="minta-akku d-md-none d-block"/>
							</MDBRow>
							</MDBCol>
							<MDBCol md="4" size="4" middle>
							<MDBRow center>
							<img src={minta_akku} className="minta-akku"/>
							</MDBRow>
							</MDBCol>
							<MDBCol md="4" size="4" middle>
							<MDBRow end>
							<img src={minta_akku} className="minta-akku d-none d-md-block"/>
							<img src={minta_akku_w} className="minta-akku d-md-none d-block"/>
							</MDBRow>
							</MDBCol>
							</MDBRow>
							<MDBRow className="in-safe-bottom-row">
							<span className="in-safe-bottom">Praesent accumsan vulputate magna, eget ullamcorper tellus eleifend et. Nunc volutpat mauris in felis pellentesque feugiat.</span>
							</MDBRow>
						</MDBCol>
						<MDBCol md="4" size="12" middle>
							<MDBRow  className="landing-in-save-row">
							<span className="in-safe-top">Lorem Ipsum Dolor</span>
							</MDBRow>
							<MDBRow className="in-safe-middle-row">
							<span className="in-safe-middle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit.<br/><br/>
							Praesent accumsan vulputate magna, eget ullamcorper tellus eleifend et. Nunc volutpat mauris in felis pellentesque feugiat. Ut ut odio vel ex congue dapibus. Vestibulum non libero tellus. Integer efficitur felis quis libero venenatis, vitae aliquam quam finibus. Duis gravida dictum justo, maximus consectetur leo consequat sit amet.</span>
							</MDBRow>
							<MDBRow className="in-safe-icon">
							<MDBCol md="4" size="4" middle>
							<MDBRow start>
							<img src={minta_akku} className="minta-akku"/>
							</MDBRow>
							</MDBCol>
							<MDBCol md="4" size="4" middle>
							<MDBRow center>
							<img src={minta_akku} className="minta-akku d-none d-md-block"/>
							<img src={minta_akku_w} className="minta-akku d-md-none d-block"/>
							</MDBRow>
							</MDBCol>
							<MDBCol md="4" size="4" middle>
							<MDBRow end>
							<img src={minta_akku} className="minta-akku"/>
							</MDBRow>
							</MDBCol>
							</MDBRow>
							<MDBRow className="in-safe-bottom-row">
							<span className="in-safe-bottom">Praesent accumsan vulputate magna, eget ullamcorper tellus eleifend et. Nunc volutpat mauris in felis pellentesque feugiat.</span>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
				</div>
				</MDBContainer>

				<div className={styles.bodywrapper} className="d-md-none d-block pt-3">
					<MDBContainer style={{maxWidth: "1430px"}}>
						{termeklist3}
					</MDBContainer>
				</div>

				<MDBContainer fluid className="fluid-container">
				<div className="d-md-none d-block">
					<div className={styles.bodywrapper4}>
						<MDBContainer style={{maxWidth: "1430px"}}>
							<MDBRow className="landing-about-row">
								<span className="services">About us</span>
							</MDBRow>
							<MDBRow center className="landing-section4-row">
								<video controls width="100%" height="auto" autoPlay loop muted>
									<source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4" type="video/mp4"/>
								</video>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>
				</MDBContainer>


				<MDBContainer fluid className="section fluid-container  without-height">
				<div className="d-none d-md-block">
					<div className={styles.bodywrapper4}>
							<MDBRow center className="landing-section4-row">
								<video controls width="100%" height="auto" autoPlay loop muted>
									<source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4" type="video/mp4"/>
								</video>
							</MDBRow>
					</div>
				</div>
				</MDBContainer>



				<MDBContainer fluid className="section fluid-container  without-height">
				<div className="d-none d-md-block">
				<div className={styles.bodywrapper5}>
				<MDBContainer style={{maxWidth: "1430px"}}>
					<MDBRow>
								<form className="landing-email-form" onSubmit={this.handleSend}>
									<MDBRow className="landing-kapcsolat-row">
										<MDBCol middle md="6" size="12" className="landing-padding-right-kapcsolat">
											<p className="landing-email-kapcsolat">Kapcsolat</p>
										</MDBCol>
										<MDBCol middle md="6" className="pl-5 d-none d-md-block">
											<p className="dijmentes-tanacsadas">Díjmentes tanácsadásra és tesztvezetésre állunk rendelkezésre!</p>
										</MDBCol>
									</MDBRow>
									<MDBRow style={{maxWidth: "100%"}}>
									<MDBCol size="12" md="6" bottom className="landing-padding-right">
										<MDBRow className="landing-email-kapcsolat-email landing-form-email-row" >
											<label htmlFor="defaultFormContactEmailEx" className="grey-text">
												<span className="your-email-label">Your Email Address</span>
											</label>
											<input type="email" id="defaultFormContactEmailEx" name="email" className="form-control" onChange={this.changeHandler} value={this.state.email}/>
										</MDBRow>
									</MDBCol>
									<MDBCol md="6" bottom className="pl-5 d-none d-md-block">
									<MDBRow className="landing-email-kapcsolat-email modal-form-email-row" >
										<MDBBtn className="tesztvezetes-btn-landing-invers btn-dark shadow-no">
												Tesztvezetés
										</MDBBtn>
										</MDBRow>
									</MDBCol>
									</MDBRow>
									<MDBRow style={{maxWidth: "100%"}}>
									<MDBCol md="6" size="12" className="landing-padding-right">
									<MDBRow className="landing-email-kapcsolat-email landing-form-email-row">
										<label htmlFor="defaultFormContactSubjectEx" className="grey-text">
											<span className="your-email-label">Telefonszám</span>
										</label>
										<input type="text" id="defaultFormContactSubjectEx" name="subject" className="form-control" onChange={this.changeHandler} value={this.state.subject}/>
									</MDBRow>
									{/*}<MDBRow className="landing-email-kapcsolat-text landing-form-email-row">
										<label htmlFor="defaultFormContactMessageEx" className="grey-text">
											<span className="your-email-label">Message</span>
										</label>
										<textarea type="text" id="defaultFormContactMessageEx" name="message" className="form-control" rows="3" onChange={this.changeHandler} value={this.state.message}/>
									</MDBRow>*/}
									<MDBRow className="landing-email-kapcsolat-text landing-form-email-row">
			              <label htmlFor="defaultFormContactCompanyNameEx" className="grey-text">
			                <span className="your-name-label">Company name</span>
			              </label>
			              <input type="text" id="defaultFormContactCompanyNameEx" name="companyname" className="form-control" onChange={this.changeHandler} value={this.state.companyname} required />
			            </MDBRow>
									<MDBRow className="align-items-top modal-form-checkbox-row">
			              <div className="custom-control custom-checkbox" >
			                <input type="checkbox" className="custom-control-input" id="invalidCheck2" required />
			                <label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck2">
			                Elfogadom az <a id="1" href="/Nutzungsbedingungen" target="_blank">Adatkezelési Nyilatkozat</a>-ban foglaltakat.</label>
			              </div>
									</MDBRow>
									</MDBCol>
									<MDBCol middle md="6">
									<img src={carE_2} className="car-e-1"/>
									</MDBCol>
									</MDBRow>
									<MDBRow style={{maxWidth: "100%", paddingBottom: "36px"}}>
									<MDBCol md="6" size="12" className="landing-padding-right">
									<MDBRow end className="landing-email-kapcsolat-button">
										<MDBBtn className="landing-btn-send-email" type="submit">
												Send
										</MDBBtn>
									</MDBRow>
									</MDBCol>
									<MDBCol md="6" className="pl-5 d-none d-md-block">
									<MDBRow center className="landing-email-kapcsolat-button">
										<MDBCol>
											<MDBRow center className="landing-phonenumber">
												<span className="landing-phonenumber-text">+36 70 600 4 500</span>
											</MDBRow>
										</MDBCol>
										<MDBCol>
											<MDBRow center className="landing-phonenumber">
												<span className="landing-phonenumber-text">info@grunschock.hu</span>
											</MDBRow>
										</MDBCol>
									</MDBRow>
									</MDBCol>
										</MDBRow>
								</form>
					</MDBRow>
				</MDBContainer>
				</div>
				</div>
				</MDBContainer>



				<MDBContainer fluid className="fluid-container">
				<div className="d-md-none d-block">
				<div className={styles.bodywrapper5}>
				<MDBContainer style={{maxWidth: "1430px"}}>
					<MDBRow>
								<form className="landing-email-form" onSubmit={this.handleSend}>
									<MDBRow className="landing-kapcsolat-row">
										<MDBCol middle size="12" className="landing-padding-right-kapcsolat">
											<p className="landing-email-kapcsolat">Kapcsolat</p>
										</MDBCol>
									</MDBRow>
									<MDBRow style={{maxWidth: "100%"}}>
										<MDBCol size="12" bottom className="landing-padding-right">
											<MDBRow className="landing-email-kapcsolat-email landing-form-email-row" >
												<label htmlFor="defaultFormContactEmailEx" className="grey-text">
													<span className="your-email-label">Your Email Address</span>
												</label>
												<input type="email" id="defaultFormContactEmailEx" name="email" className="form-control" onChange={this.changeHandler} value={this.state.email}/>
											</MDBRow>
										</MDBCol>
									</MDBRow>
									<MDBRow style={{maxWidth: "100%"}}>
										<MDBCol size="12" className="landing-padding-right">
											<MDBRow className="landing-email-kapcsolat-email landing-form-email-row">
												<label htmlFor="defaultFormContactSubjectEx" className="grey-text">
													<span className="your-email-label">Telefonszám</span>
												</label>
												<input type="text" id="defaultFormContactSubjectEx" name="subject" className="form-control" onChange={this.changeHandler} value={this.state.subject}/>
											</MDBRow>
											{/*<MDBRow className="landing-email-kapcsolat-text landing-form-email-row">
												<label htmlFor="defaultFormContactMessageEx" className="grey-text">
													<span className="your-email-label">Message</span>
												</label>
												<textarea type="text" id="defaultFormContactMessageEx" name="message" className="form-control" rows="3" onChange={this.changeHandler} value={this.state.message}/>
											</MDBRow>*/}
											<MDBRow className="landing-email-kapcsolat-text landing-form-email-row">
					              <label htmlFor="defaultFormContactCompanyNameEx" className="grey-text">
					                <span className="your-name-label">Company name</span>
					              </label>
					              <input type="text" id="defaultFormContactCompanyNameEx" name="companyname" className="form-control" onChange={this.changeHandler} value={this.state.companyname} required />
					            </MDBRow>
											<MDBRow className="align-items-top modal-form-checkbox-row">
					              <div className="custom-control custom-checkbox" >
					                <input type="checkbox" className="custom-control-input" id="invalidCheck2" required />
					                <label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck2">
					                Elfogadom az <a id="1" href="/Nutzungsbedingungen" target="_blank">Adatkezelési Nyilatkozat</a>-ban foglaltakat.</label>
					              </div>
											</MDBRow>
										</MDBCol>
									</MDBRow>
									<MDBRow style={{maxWidth: "100%", paddingBottom: "36px"}}>
										<MDBCol size="12" className="landing-padding-right">
											<MDBRow end className="landing-email-kapcsolat-button">
												<MDBBtn className="landing-btn-send-email" type="submit">
														Send
												</MDBBtn>
											</MDBRow>
										</MDBCol>
									</MDBRow>
								</form>
					</MDBRow>
				</MDBContainer>
				</div>
				</div>
				</MDBContainer>



				<MDBContainer fluid className="section fluid-container  without-height">
					<div className="d-none d-md-block">
						<div className={styles.bodywrapper7}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow center className="pt-3 pb-4">
									{gyiklist}
								</MDBRow>
							</MDBContainer>
						</div>
					</div>
				</MDBContainer>

				<MDBContainer fluid className="fluid-container">
					<div className="d-md-none d-block">
						<div className={styles.bodywrapper7}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow center className="pt-3 pb-4">
									{gyiklist}
								</MDBRow>
							</MDBContainer>
						</div>
					</div>
				</MDBContainer>


				<Footer/>
        </ReactFullpage.Wrapper>
				</>
      );
    }}
  />
	{this.state.showModal ?
		<MusterModal
			show={this.state.showModal}
			handleClose={this.handleClose}
			teslamodel={this.state.teslamodel}
			showTesztvezetesModal= {this.state.tesztvezetesmodal ? true : false}
			modalnev= {this.state.tesztvezetesmodal ? "tesztvezetes" : "emailfullname"}
			showEmailFullNameModal= {this.state.tesztvezetesmodal ? false : true}
			{...this.props}/>:
	false}

</>
    );
  }
}
export default  withTranslation()(LandingPage);
