import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../../assets/scss/modules/landing.module.scss';
import tesla_fantom from "../../assets/media/images/Tesla-Fantom.png";
import tesla_mask from "../../assets/media/images/Tesla-Mask.png";
import combined_shape from "../../assets/media/images/combined-shape.png";
import back from "../../assets/media/newicons/back.png";
import arrow from "../../assets/media/newicons/arrow.png";
import arrowWhite from "../../assets/media/newicons/arrowWhite.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBIcon,
	MDBView
} from 'mdbreact';

class AutoModel extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
						<div className={styles.bodywrapper6}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow className="auto-model-padding">

									<MDBCol middle md="6" size="12">
									<MDBRow center>
										<MDBView hover zoom style={{padding: "35px"}}>
											<img
												src={this.props.msg.urlName} className="tesla-model-3 d-none d-md-block"
												alt=""
											/>
										</MDBView>
										<img
											src={this.props.msg.urlName} className="tesla-model-3 d-md-none d-block"
											alt=""
										/>
									</MDBRow>
									</MDBCol>
									<MDBCol middle md="6" size="12" className="tesla-col2">
										<MDBRow start className="tesla-back-btn-row">
											<MDBBtn
													flat
													className="tesla-back-btn"
													type="btn"
													href= "/"
													>
													<img src={back} className="tesla-back-img"/>
											</MDBBtn>
										</MDBRow>
										<MDBRow start className="tesla-model-text-row d-none d-md-block">
											<p className="tesla-model-text">{this.props.msg.manufacturer + " " + this.props.msg.model}</p>
										</MDBRow>
										<MDBRow center className="tesla-model-text-row d-md-none d-block">
											<p className="tesla-model-text text-center">{this.props.msg.manufacturer + " " + this.props.msg.model}</p>
										</MDBRow>
										<div className="tesla-model-daten-row">
                    {this.props.msg.range !== 0 ?
                    <MDBRow >
											<MDBCol md="5" size="6">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">Range</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.range + " km"}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" size="6">
											</MDBCol>
										</MDBRow>:false}

                    {this.props.msg.acceleration_to_100 !== 0 ?
										<MDBRow>
											<MDBCol md="5" size="6">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">0-100 km/h</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.acceleration_to_100 + " s"}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" size="6">
											</MDBCol>
										</MDBRow>:false}

                    {this.props.msg.dualMotor !== null ?
										<MDBRow>
											<MDBCol md="5" size="6">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">Dual motor</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.dualMotor}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" size="6">
											</MDBCol>
										</MDBRow>:false}

                    {this.props.msg.acceleration_quarter_mile !== 0 ?
										<MDBRow>
											<MDBCol md="5" size="6">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">1/4 miles</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.acceleration_quarter_mile + " s"}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" size="6">
											</MDBCol>
										</MDBRow>:false}

                    {this.props.msg.top_speed_kmh !== 0 ?
										<MDBRow>
											<MDBCol md="5" size="6">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">Speed</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.top_speed_kmh + " km/h"}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" size="6">
											</MDBCol>
										</MDBRow>:false}

                    {this.props.msg.storage !== 0 ?
										<MDBRow>
											<MDBCol md="5" size="6">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">Storage</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.storage + " l"}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" size="6">
											</MDBCol>
										</MDBRow>:false}

										{this.props.msg.power_hp !== 0 ?
										<MDBRow>
											<MDBCol md="5" size="6">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">Power</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.power_hp + " LE"}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" size="6">
											</MDBCol>
										</MDBRow>:false}

                    {this.props.msg.battery_kwh !== 0 ?
										<MDBRow>
											<MDBCol md="5">
												<MDBRow>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-daten">Battery</span>
													</MDBCol>
													<MDBCol md="6" size="6" className="nopadding-nomargin-col">
														<span className="tesla-param">{this.props.msg.battery_kwh + " kwh"}</span>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7">
											</MDBCol>
										</MDBRow>:false}
										</div>

										<MDBRow className="tesla-combined-shape-row">
											<MDBCol md="4" size="4">
												<MDBRow center>
												<span className="refundable-mon">117 € / m</span>
												</MDBRow>
												<MDBRow center>
												<img src={combined_shape} className="combined-shape"/><span className="refundable">Refundable VAT</span>
												</MDBRow>
											</MDBCol>
											<MDBCol md="4" size="4">
												<MDBRow center>
												<span className="refundable-mon">1678 € / y</span>
												</MDBRow>
												<MDBRow center>
												<img src={combined_shape} className="combined-shape"/><span className="refundable">Est. fuel saving</span>
												</MDBRow>
											</MDBCol>
											<MDBCol md="4" size="4">
												<MDBRow center>
												<span className="refundable-mon">145 € / y</span>
												</MDBRow>
												<MDBRow center>
												<img src={combined_shape} className="combined-shape"/><span className="refundable-tax">Tax saving</span>
												</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="tesla-opened-ended-row">
											<MDBCol md="6" size="6" className="px-0">
											<MDBRow start style={{paddingLeft: "15px"}}>
											<div className="tesla-rectangle">
												<MDBRow start className="open-ended-row">
													<span className="open-ended">Opened-ended</span>
												</MDBRow>
												<MDBRow start className="lease-row">
													<span className="lease">Lease</span>
												</MDBRow>
												<MDBRow end className="get-an-offer-row">
												<MDBBtn
														flat
														className="tesla-arrow-btn"
														type="btn"
														onClick={this.props.handleNewTesla}
														>
														<span className="get-an-offer text-capitalize">Get an Offer</span><img src={arrow} className="arrow"/>
												</MDBBtn>
												</MDBRow>
											</div>
											</MDBRow>
											</MDBCol>
											<MDBCol md="6" size="6" className="px-0">
											<MDBRow end  style={{paddingRight: "15px"}}>
											<div className="tesla-rectangle">
												<MDBRow start className="open-ended-row">
													<span className="open-ended">Instant</span>
												</MDBRow>
												<MDBRow start className="lease-row">
													<span className="lease">Buy</span>
												</MDBRow>
												<MDBRow end className="get-an-offer-row">
												<MDBBtn
														flat
														className="tesla-arrow-btn"
														type="btn"
														href= "/"
														>
														<span className="get-an-offer text-capitalize">Get an Offer</span><img src={arrow} className="arrow"/>
												</MDBBtn>
												</MDBRow>
											</div>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="tesla-rectangle-padding-bottom">
											<MDBCol md="12" size="12" className="px-0">
											<div className="tesla-rectangle-1">
												<MDBRow className="open-ended-row">
													<span className="best-offer">Best Offer</span>
												</MDBRow>
												<MDBRow>
													<MDBCol md="6" size="7">
														<MDBRow start className="lease-row">
															<span className="long-term-rental">Long-term rentalr</span>
														</MDBRow>
													</MDBCol>
													<MDBCol md="6" size="5">
														<MDBRow end className="get-an-offer-row">
															<span className="price-€-month">990 € / month</span>
														</MDBRow>
													</MDBCol>
												</MDBRow>
												<MDBRow end className="get-an-offer-row">
													<MDBBtn
															flat
															className="tesla-arrow-btn"
															type="btn"
															onClick={() => this.props.handleShow(this.props.msg.manufacturer + " " + this.props.msg.model)}
															>
															<span className="get-an-offer-w text-capitalize">Get an Offer</span><img src={arrowWhite} className="arrow"/>
													</MDBBtn>
												</MDBRow>
												</div>
											</MDBCol>
										</MDBRow>
									</MDBCol>
								</MDBRow>
								<hr className="hr-style"/>
							</MDBContainer>
						</div>
    );
  }
}
export default  withTranslation()(AutoModel);
