import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import combined_shape from "../../assets/media/images/combined-shape.png";
import close from "../../assets/media/newicons/close.png";
import done from "../../assets/media/newicons/done.png";
import inactive from "../../assets/media/newicons/inactive.png";
import active from "../../assets/media/newicons/active.png";
import{
	MDBContainer,
	MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdbreact';

class ModalThankYou extends Component {
  state = {
						};


  render() {
    const { t } = this.props;
    const client = this.props.client;
    return (
      <>
          <MDBRow center className="align-items-center modal-teslamodel-row">
						<MDBCol middle md="3" size="2">
						</MDBCol>
						<MDBCol middle md="6" size="8" className="text-center">
						<img src={combined_shape} className="thankyou-combined-shape"/>
						</MDBCol>
						<MDBCol middle md="3" size="2" className="text-right">
						<MDBBtn flat onClick={this.props.handleClose} className="togglebtn"><img src={close} className="img-toggle-close"/></MDBBtn>
						</MDBCol>
          </MDBRow>
          <MDBRow center className="align-items-center">
            <span className="thankyou-thank-you">Thank You!</span>
          </MDBRow>
          <MDBRow center className="align-items-center thankyou-our-colleagues-row">
            <span className="thankyou-order-details">Order details has been sent to <span className="thankyou-order-details-green">{this.props.email}</span> adress.</span>
          </MDBRow>
          <MDBRow center className="align-items-center modal-param-row">
            <span className="thankyou-print">Print</span>
          </MDBRow>
					<MDBRow center className="align-items-center thankyou-our-colleagues-row">
            <span className="thankyou-our-colleagues">Our colleagues will reach out to you for further actions. If you have any questions feel free to contact us.</span>
          </MDBRow>
					<MDBRow center className="align-items-center modal-param-row">
            <span className="thankyou-print">info@grunschock.hu</span>
          </MDBRow>
					<MDBRow center className="align-items-center modal-param-row">
            <span className="thankyou-print">+36 70 600 4 500</span>
          </MDBRow>
          <MDBRow center className="align-items-center  modal-form-home-row">
            <MDBCol md="6" middle className="text-center">
              <MDBBtn className="modal-btn-send-email"  type="btn" onClick={this.props.handleCloseThankYou}>
                  Home
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </>
    );
  }
}

export default withTranslation()(ModalThankYou);
