import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import {BrowserRouter as Router, Route } from 'react-router-dom';

import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNav,
  MDBNavbarNav,
  MDBSideNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBFormInline,
  MDBInputGroup,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBCard
} from 'mdbreact';

import logo from './assets/media/newicons/logo.png';
import logo_w from './assets/media/newicons/logo-w.png';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';


import Cookies from 'universal-cookie';
const cookies = new Cookies();

class NavbarComponent extends Component {

	constructor(props) {
    super(props);
    let urlstart = window.location.href;
    if(!urlstart.includes('Tesla') && !urlstart.includes('Kia') && !urlstart.includes('Hyundai') && !urlstart.includes('Skoda') && !urlstart.includes('Volkswagen')){
      this.state = {
  								  activeItem: "2"
                  };
    }
    if(urlstart.includes('Tesla')){
      this.state = {
  								  activeItem: "3"
                  };
    }
    if(urlstart.includes('Kia')){
      this.state = {
  								  activeItem: "4"
                  };
    }
    if(urlstart.includes('Hyundai')){
      this.state = {
  								  activeItem: "5"
                  };
    }
    if(urlstart.includes('Skoda')){
      this.state = {
  								  activeItem: "6"
                  };
    }
    if(urlstart.includes('Volkswagen')){
      this.state = {
  								  activeItem: "7"
                  };
    }

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
	}
  var x = document.getElementById("toggler");
    if (window.getComputedStyle(x).display !== "none") {
      this.setState({ isOpen: !this.state.isOpen});
    }
}

  toGerman = () => {
    i18next.changeLanguage('de', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      this.setBackendLanguage('de');
    });
  }

  toEnglish = () => {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      this.setBackendLanguage('en');
    });
  }

  toggleCollapse = () => {
    var x = document.getElementById("toggler");
      if (window.getComputedStyle(x).display !== "none") {
        this.setState({ isOpen: !this.state.isOpen});
      }
  }

	render() {
    let url = window.location.href;
    const { t } = this.props;
    const client = this.props.client;
		return (
			<>
      <MDBNavbar expand='md' fixed='top' transparent >
      				<MDBContainer className="navbarcomponent-nav-container">
      					<MDBNavbarBrand href='/'>
      						<img src={!this.props.green ? logo : logo_w} className="d-none d-md-block"/>
                  <img src={logo} className="d-md-none d-block"/>
      					</MDBNavbarBrand>
      					<MDBNavbarToggler id="toggler" onClick={this.toggleCollapse} />
      					<MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar className="d-none d-md-block">

                  <MDBNavbarNav className="navbarcomponent-nav navcard">
                    <MDBNavItem active={!url.includes('Tesla') && !url.includes('Kia') && !url.includes('Hyundai') && !url.includes('Skoda') && !url.includes('Volkswagen')}>
                    <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavLink link to="/" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" ><span className="navbar-text offers">Offers</span></MDBNavLink>
                    </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Tesla')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavLink link to="/Tesla" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" ><span className="navbar-text tesla">Tesla</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Kia')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavLink link to="/Kia" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab" ><span className="navbar-text kia">Kia</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Hyundai')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavLink link to="/Hyundai" active={this.state.activeItem === "5"} onClick={this.toggle("5")} role="tab" ><span className="navbar-text hyundai">Hyundai</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Skoda')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavLink link to="/Skoda" active={this.state.activeItem === "6"} onClick={this.toggle("6")} role="tab" ><span className="navbar-text skoda">Skoda</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Volkswagen')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavLink link to="/Volkswagen" active={this.state.activeItem === "7"} onClick={this.toggle("7")} role="tab" ><span className="navbar-text volkswagen">Volkswagen</span></MDBNavLink>
                      </div>
                    </MDBNavItem>



      						</MDBNavbarNav>

      					</MDBCollapse>
                <MDBSideNav
                  logo={logo}
                  hidden
                  right
                  triggerOpening={this.state.isOpen}
                  breakWidth={800}
                  className="darken-4 d-md-none d-block"
                >

                  <MDBNavbarNav className="navbarcomponent-nav navcard">
                    <MDBNavItem active={!url.includes('Tesla') && !url.includes('Kia') && !url.includes('Hyundai') && !url.includes('Skoda') && !url.includes('Volkswagen')}>
                    <div className="navbar-notgreen">
                      <MDBNavLink link to="/" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" ><span className="navbar-text offers">Offers</span></MDBNavLink>
                    </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Tesla')}>
                      <div className="navbar-notgreen">
                      <MDBNavLink link to="/Tesla" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" ><span className="navbar-text tesla">Tesla</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Kia')}>
                      <div className="navbar-notgreen">
                      <MDBNavLink link to="/Kia" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab" ><span className="navbar-text kia">Kia</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Hyundai')}>
                      <div className="navbar-notgreen">
                      <MDBNavLink link to="/Hyundai" active={this.state.activeItem === "5"} onClick={this.toggle("5")} role="tab" ><span className="navbar-text hyundai">Hyundai</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Skoda')}>
                      <div className="navbar-notgreen">
                      <MDBNavLink link to="/Skoda" active={this.state.activeItem === "6"} onClick={this.toggle("6")} role="tab" ><span className="navbar-text skoda">Skoda</span></MDBNavLink>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem active={url.includes('Volkswagen')}>
                      <div className="navbar-notgreen">
                      <MDBNavLink link to="/Volkswagen" active={this.state.activeItem === "7"} onClick={this.toggle("7")} role="tab" ><span className="navbar-text volkswagen">Volkswagen</span></MDBNavLink>
                      </div>
                    </MDBNavItem>



      						</MDBNavbarNav>

      					</MDBSideNav>
                <div className="phonenumber text-center d-none d-md-block">
                <span className="phonenumber-text">+36 70 600 4 500</span>
                </div>
      					</MDBContainer>

      				</MDBNavbar>






</>
		);
	}
}

export default withTranslation()(NavbarComponent);
